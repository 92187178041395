<template>
    <div>
        <onboarding-layout id="verifyEmailadres" data-view="verify-emailaddress">
            <template slot="title"> {{ $t('emailverification.email_not_verified') }} </template>
            <template slot="lead-text"> {{ $t('emailverification.email_not_verified_text') }} </template>

            <submit-button class="mt-5 mr-3" data-action="verify-email" @click="handleResendVerificationEmail">{{
                $t('emailverification.resend')
            }}</submit-button>
            <submit-button class="mt-5" @click="logout">{{ $t('general.logout') }}</submit-button>
        </onboarding-layout>
        <Loader v-if="isLoading" />
    </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { mapActions, mapState } from 'vuex';
import OnboardingLayout from '../layouts/OnboardingLayout.vue';

export default {
    components: {
        Loader,
        OnboardingLayout
    },

    mixins: [formRulesMixin],

    data() {
        return {
            loading: false
        };
    },

    created() {
        if (this.isEmailVerified) {
            return this.$router.replace({ name: 'dashboard' });
        }
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        isLoading() {
            return this.loading;
        },
        isEmailVerified() {
            if (!this.currentUserData || this.currentUserData.userinformation) {
                return false;
            }
            return this.currentUserData.userinformation.verifiedEmailadres;
        }
    },

    methods: {
        ...mapActions('auth', ['logout', 'resendVerificationemail']),
        async handleResendVerificationEmail() {
            try {
                this.loading = true;

                await this.resendVerificationemail();

                notify.call(this, {
                    title: this.$t('emailverification.resend_verification_email'),
                    text: this.$t('emailverification.resend_verification_email')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.Content {
    background-color: white;
}

.center-checkbox {
    height: 100%;
    align-items: center;

    > div {
        padding-top: 0px !important;
        margin-top: 0px !important;
    }
}

.v-stepper__content {
    padding: 0px;
}

.info-link {
    padding: 0px 12px;
    margin: 0px;
    font-size: 12px;
    vertical-align: top;
    line-height: 12px;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}
</style>
